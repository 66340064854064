import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
};


export const Layout = () => {
  return <>
    <ScrollToTop />
    <Header/>
    <Outlet />
    <Footer/>
  </>;
};

export default Layout;